import { render, staticRenderFns } from "./typeFaq.vue?vue&type=template&id=11cff776&scoped=true&"
import script from "./typeFaq.vue?vue&type=script&lang=js&"
export * from "./typeFaq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11cff776",
  null
  
)

export default component.exports